import {OrderDto, orderService} from "../../../../../domain";
import {OrderFormListener} from "../../order-form.props";

export const onApplyDiscount = (orderId: number, discountValue: number, discountType: string, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
    return orderService
        .applyDiscount(orderId, discountType ,discountValue)
        .then(res => {
            if (res && listener) {
                listener({type: 'confirmed', sender: res});
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
