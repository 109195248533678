import React from "react";
import { computeTawreedActionTitle, TawreedAction, TawreedActionDialog } from "../../../../../../../../common/actions";
import { Button } from "primereact/button";
import { tawreedTranslationService } from "../../../../../../../../common/translations";
import { PrimeIcons } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { TawreedFormField, TawreedInputNumber } from "../../../../../../../../common";
import { DiscountTypesDropDown } from "../../../../../../../catalog/store-products/presentaion/components/discount-types";
import { InvoiceDiscountTypesDropDown } from "../../Invoice-discount-type-drop-down";

export type OrderApplyDiscountDialogProps = {
    orderId?: number | undefined,
    invoiceDiscountType: string | undefined;
    invoiceDiscountValue: number | undefined;

};
export type ApplyDiscountPromise = {
    discountType: string | undefined;
    discountValue: number | undefined;
};
export type OrderApplyDiscountDialogState = {
    /**
     *
     */
    invoiceDiscountType: string | undefined;
    invoiceDiscountValue: number | undefined;

    visible: boolean;

    title: string;
}

export class OrderApplyDiscountDialog extends React.Component<OrderApplyDiscountDialogProps, OrderApplyDiscountDialogState> implements TawreedActionDialog<any, any, ApplyDiscountPromise> {

    constructor(props: OrderApplyDiscountDialogProps) {
        super(props);
        this.state = {
            title: '',
            invoiceDiscountType: props.invoiceDiscountType,
            invoiceDiscountValue: props.invoiceDiscountValue,
            visible: false,
        };
    }

    private resolve: ((applyDiscountPromise:ApplyDiscountPromise) => void) | undefined;
    private reject: (() => void) | undefined;

    private OrderApplyDiscountDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} onClick={() => this.onHide()} className="p-button-text" />
                <Button label={tawreedTranslationService.translate('lbl_accept')}
                    icon={PrimeIcons.CHECK}
                    onClick={() => this.onAccept()}
                    disabled={!this.state || !this.state.invoiceDiscountType || !this.state.invoiceDiscountValue} />
            </div>
        );
    }

    private onHide(): void {
        if (this.reject) {
            this.setState({ ...this.state, visible: false, invoiceDiscountType: undefined, invoiceDiscountValue: undefined });
            this.reject();
        }
    }

    private onAccept(): void {
        if (this.resolve) {
            if (this.state.invoiceDiscountType && this.state.invoiceDiscountValue) {
                this.resolve({
                    discountType: this.state.invoiceDiscountType,
                    discountValue: this.state.invoiceDiscountValue
                });
                this.setState({ ...this.state, visible: false, invoiceDiscountType: undefined, invoiceDiscountValue: undefined });

            }
        }
    }

    // private onCarrierSelect(value: number): void {
    //     this.setState({ ...this.state, carrier: value })
    // }

    public show(action: TawreedAction): Promise<ApplyDiscountPromise> {
        //
        const title = computeTawreedActionTitle({ state: this.state }, action.title);
        //
        this.setState({ ...this.state, visible: true, title: title, invoiceDiscountType: this.props.invoiceDiscountType, invoiceDiscountValue: this.props.invoiceDiscountValue });
        return new Promise<ApplyDiscountPromise>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public render() {
        //
        //
        return (
            <Dialog header={tawreedTranslationService.translate(this.state.title)}
                footer={this.OrderApplyDiscountDialogFooter()}
                onHide={() => this.onHide()}
                visible={this.state.visible}
                position="top" style={{ width: '55vw' }}
                draggable={false}
                closable={true}
                onShow={() => {
                    this.setState({
                        visible: true
                    });
                }}
                focusOnShow
                id="orders_confirm_dialog"
                closeOnEscape={true}
                >
                <div className="grid formgrid">
                <TawreedFormField className="field col-6 lg:col-6 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                            <InvoiceDiscountTypesDropDown value={this.state?.invoiceDiscountType} name="discountType" render="standalone" onChange={(e) => {
                                this.setState({ ...this.state, invoiceDiscountType: e.value })
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="bonus" title={"lbl_store_products_discount_bonus_val"} className="field col-6 lg:col-6 md:col-6">
                            <TawreedInputNumber mode={'decimal'} value={this.state?.invoiceDiscountValue} name="bonus" render="standalone" onChange={(e) => {
                                this.setState({ ...this.state, invoiceDiscountValue: e.value ? e.value : 0 });
                            }} />
                        </TawreedFormField>
                </div>
            </Dialog>
        );
    }
}
