import React from "react";
import { Panel } from "primereact/panel";

import { TawreedControlProps, TawreedControlRenderModeWithoutNested, TawreedFormField, TawreedInputCustom, TawreedInputPlain, TawreedNavLink } from "../../../../common/components/form";
import { tawreedTranslationService } from "../../../../common/translations";
import { ObjectUtils } from "../../../../helpers/object";
import { PartnerFiles } from "./partner-files";
import { PartnerDto } from "../../domain";
import { PartnerType } from "../../data";
import { GlobalContext } from "../../../../context";

export type PartnerCardProps =
    {
        /**
         * 
         */
        className?: string;

        /**
         * 
         */
        title: string;

        showFiles?: boolean;
    }
    &
    TawreedControlProps<PartnerDto>;

export const PartnerCard: React.FC<PartnerCardProps> = (props) => {

    //
    const [value, setValue] = React.useState<any>({});
    const { auth: { user } } = React.useContext(GlobalContext);
    const [render, setRender] = React.useState<TawreedControlRenderModeWithoutNested>('form');

    React.useEffect(() => {

        let mounted = true;
        if (mounted) {
            if (props.render === 'standalone' && props.value) {
                setValue({
                    partnerName: ObjectUtils.getNested(props.value, 'partnerName'),
                    contact: {
                        mobileNo: ObjectUtils.getNested(props.value, 'contact.mobileNo'),
                    },
                    address: {
                        address: ObjectUtils.getNested(props.value, 'address.address'),
                    },
                    businessType: ObjectUtils.getNested(props.value, 'businessType'),
                });
                setRender('standalone');
            } else {
                setValue({});
                setRender('form');
            }
        }
        return () => { mounted = false; };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.render]);

    return (
        <Panel header={tawreedTranslationService.translate(props.title)} className={props.className ?? ''}>
             <div className="formgrid grid">
                <TawreedFormField className="field col-12" name={`${props.name}.partnerName`} title="lbl_partners_partner_name">
                    <TawreedInputPlain name={`${props.name}.partnerName`} value={value.partnerName} render={render} />
                </TawreedFormField>
                {((props.name === 'customer' && user?.roleCode !== 'STORE_ADMIN' && user?.roleCode !== 'STORE_DELIVERY' && user?.roleCode !=='STORE_SALES_MAN'
                && user?.roleCode !== 'STORE_REVIEWER' && user?.roleCode !=='STORE_WH') || ( props.name === 'store' || props.name ==='carrier')) &&
                <React.Fragment>
                <TawreedFormField className="field col-12" name={`${props.name}.contact.mobileNo`} title="lbl_partners_contact_mobile">
                    <TawreedNavLink render={render} name={`${props.name}.contact.mobileNo`} to={(mobileNo: string) => 'tel:' + mobileNo}>
                        <TawreedInputPlain name={`${props.name}.contact.mobileNo`} value={value.contact?.mobileNo} render={render} />
                    </TawreedNavLink>
                </TawreedFormField>
                </React.Fragment>
                }
                {((props.name === 'customer' && user?.roleCode !== 'STORE_DELIVERY' && user?.roleCode !=='STORE_SALES_MAN'
                && user?.roleCode !== 'STORE_REVIEWER' && user?.roleCode !=='STORE_WH') || ( props.name === 'store' || props.name ==='carrier')) &&
                <React.Fragment>
                
                <TawreedFormField className="field col-12" name={`${props.name}.establishmentNationalId`} title="lbl_partners_establishmentnationalid">
                    <TawreedInputPlain name={`${props.name}.establishmentNationalId`} value={value.establishmentNationalId} render={render} />
                </TawreedFormField>

                <TawreedFormField className="field col-12" name={`${props.name}.nationalId`} title="lbl_partners_national_id">
                    <TawreedInputPlain name={`${props.name}.nationalId`} value={value.nationalId} render={render} />
                </TawreedFormField>
                </React.Fragment>
                }
               
                <TawreedFormField className="field col-12" name={`${props.name}.address.fullAddress`} title="lbl_partners_address_address">
                    <TawreedNavLink render={render} name={props.name} nested="address" to={({ location }) => location}>
                        <TawreedInputPlain render={render} name={`${props.name}.address.fullAddress`} value={value.address?.fullAddress} />
                    </TawreedNavLink>
                </TawreedFormField>

                {
                    props.showFiles && user?.roleCode !== 'STORE_DELIVERY' && user?.roleCode !=='STORE_SALES_MAN'
                    && user?.roleCode !== 'STORE_REVIEWER' && user?.roleCode !=='STORE_WH' && 
                    <React.Fragment>
                        {
                            render === 'standalone' &&
                            <PartnerFiles render="standalone" name={props.name} partnerType={value?.partnerType} businessType={value?.businessType} disabled />
                        }
                        {
                            render === 'form' &&
                            <TawreedInputCustom render="form" name={`${props.name}.partnerType`}
                                renderer={(partnerType: PartnerType) =>
                                    <TawreedInputCustom render="form" name={`${props.name}.businessType`}
                                        renderer={(businessType: number) =>
                                            <PartnerFiles render="form" name={`${props.name}.files`} partnerType={partnerType} businessType={businessType} />} />} disabled />
                        }
                    </React.Fragment>
                }
            </div>
        </Panel>
    );
}
