import React from 'react'
import { AuthUtils } from '../../../../auth/domain';
import { TawreedDropdown, TawreedDropdownProps } from '../../../../../common/components/form'
import { CartService } from '../../domain';
import { paymentModeDto } from '../../domain/dtos';
import { PaymentItemTemplate } from './payment-mode.item-template';

export const CartPaymentModeDropdown: React.FC<TawreedDropdownProps & { priceListId: number, cartId: number, onPreSelected?: (e: paymentModeDto) => void }> = (props) => {

    // di
    const service = React.useMemo(() => new CartService(), []);

    // state
    const [loading, setLoading] = React.useState(false)
    const [paymentModes, setPaymentModes] = React.useState<paymentModeDto[]>([]);

    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(true);
            service.getPaymentsMode(props.cartId)
                .then(res => {
                    if (mounted) {

                        if (res.length === 1) {
                            props.onPreSelected && props.onPreSelected(res[0]);
                        }
                        setPaymentModes(res);

                    }
                })
                .finally(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        }

        return () => { mounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service, props.cartId]);

    return (<TawreedDropdown {...props} disabled={loading}
        itemTemplate={e => <PaymentItemTemplate rowData={e}/>}
        options={paymentModes} optionValue={props.optionValue ? (props.optionValue === 'object' ? undefined : props.optionValue) : 'paymentModeId'} optionLabel={AuthUtils.current().language === 'en' ? 'titleEn' : 'titleAr'} filter />)

}
