import React, { FC, useMemo, useRef, useState } from 'react'
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { ImportStoreProductHelperService, ImportStoreProductTaskDto, StoreProductsStageService } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { GlobalContext } from '../../../../../context';
import { Tag } from 'primereact/tag';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { PrimeIcons } from 'primereact/api';
import { tawreedTranslationService } from '../../../../../common/translations';
import { IimportHelperStatus } from '../../../../constants';
import { endpoints, getBaseUrl } from '../../../../../constants/endpoints';
import { SplitButton } from 'primereact/splitbutton';
import { JObject } from '../../../../../common/data/models';
import { ImportProductsDialog } from './import_products_dialog';
export const ImportStoreProductHelperTable: FC = (_props) => {

    const service: ImportStoreProductHelperService = useMemo(() => new ImportStoreProductHelperService(), []);
    
    const service1: StoreProductsStageService = useMemo(() => new StoreProductsStageService(), []);
    const [reload, setReload] = useState(false);
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    let itemOptions: JObject | undefined = undefined;
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    
    const onImport = (): Promise<void> => {
        return new Promise((resolve) => {
            setImportDialogVisible(true);
            resolve();
        });
    }

    const onDownloadTemplate = (): Promise<void> => {
        return service1.downloadTemplate().then();
    }

    const getItems = (status: string) => {
        if (status === IimportHelperStatus.COMPLETED) {
            return [
                {
                    label: tawreedTranslationService.translate("lbl_download_original_file"),
                    command: (e: any) => {
                        window.open(`${process.env.REACT_APP_S3_BUCKET}/${itemOptions?.fileId}`, "_blank");
                    }
                },
                {
                    label: tawreedTranslationService.translate("lbl_downlad_invalid_result"),
                    command: (e: any) => {
                        window.open(`${process.env.REACT_APP_S3_BUCKET}/${itemOptions?.importStoreProductTaskId+'.xlsx'}`, "_blank");
                    }
                }
            ]
        }
        else if (status === IimportHelperStatus.PENDING || status === IimportHelperStatus.NOT_STARTED) {
            return [
                {
                    label: tawreedTranslationService.translate("lbl_download_original_file"),
                    command: (e: any) => {
                        window.open(`${process.env.REACT_APP_S3_BUCKET}/${itemOptions?.fileId}`, "_blank");
                    }
                },
            ]
        }
    }

    const tawreed: TawreedTableProps<ImportStoreProductTaskDto> = {
        header: true,
        disableGlobalSearch: true,
        pagination: true,
        reload: reload,
        outLoader: loading,
        dataKey: 'importStoreProductTaskId',
        name: 'tbl.import.helper',
        title: 'lbl_import_store_product',
        ds: {
            mode: 'lazy',
            onSearch: (q) => service.search(q),
        },
        columns: [
            {
                field: "importStoreProductTaskId",
                header: "lbl_import_task_id",
                sortable: true,
                filter: true,
                style: CssUtils.width('23%'),
            },
            {
                field: "status",
                header: "lbl_sales_orders_status",
                sortable: true,
                sortField: "status",
                filter: true,
                filterField: 'status',
                ...TawreedTableFilters.dropdown.dropdown('status', constants?.importHelperStatus ?? [], 'value', 'label', 'in', (label: string) => <Tag rounded severity={'success'} style={{ width: '6rem' }} value={label} />),
                style: CssUtils.width('23%'),
            }, {
                field: "createdAt",
                header: "lbl_import_task_created_at",
                sortable: true,
                filter: true,
                ...TawreedTableFilters.date.range('createdAt', 'datetime'),
                style: CssUtils.width('15%'),
            },
            {
                field: "duration",
                header: "lbl_import_task_duration",
                body: (options: any) => {
                    return (
                        <React.Fragment>
                            <p>{options.duration ? (options.duration / 60).toFixed(0) + "m:" + options.duration % 60 + "s" : "-"}</p>
                        </React.Fragment>)
                },
                style: CssUtils.width('20%')
            },
            {
                field: "test",
                style: CssUtils.width('10%'),
                body: (options: any) => {
                    return (
                        <React.Fragment>
                            <SplitButton menuButtonClassName='p-splitbutton-menubutton' buttonClassName='hideButth' label={""} dropdownIcon="pi pi-ellipsis-v" icon="" onShow={() => { itemOptions = options }} model={getItems(options.status)} />
                        </React.Fragment>)
                }
            },

        ],

        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.StoreProductsDownloadTemplate, 'stateless', 'lbl_store_products_download_template', PrimeIcons.DOWNLOAD, onDownloadTemplate,"mr-2"),
                
            ],
        },
        canImport: new TawreedAction(TawreedActionName.StoreProductsStageImport, 'stateless', 'lbl_store_products_import', PrimeIcons.UPLOAD, onImport),

        row: {
        },

        filter: {
            filterDisplay: 'menu',
            initial: {
                'status': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'in',
                        },
                    ]
                },
                'createdAt': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'dateIs',
                        }
                    ],
                },
                'importStoreProductTaskId': {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'importStoreProductTaskId',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: 'single',
        },
    }

    return (
        <React.Fragment>
            <TawreedTable  {...tawreed} />
            <ImportProductsDialog
                uploadUrl={getBaseUrl() + endpoints.STORE_PRODUCT_STAGE_IMPORT}
                accept=".xls,.xlsx"
                visible={importDialogVisible}
                hide={() => {
                    setImportDialogVisible(false);
                    setReload(true);
                }}
                isStore={user?.roleCode === 'STORE_ADMIN' ? true : false}
                partnerId={user?.partnerId!}

            />
        </React.Fragment>
    );
}

