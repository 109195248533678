
import React, { FC, useEffect, useMemo, useState } from 'react'
import { JObject } from "../../../../../../common/data/models";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { tawreedTranslationService } from "../../../../../../common/translations";
import { PrimeIcons } from 'primereact/api';
import { TawreedFormField, TawreedInputNumber } from '../../../../../../common';
import { InvoiceDiscountTypesDropDown } from './Invoice-discount-type-drop-down';

export interface OrderApplyDiscountDialogProps {
    visible: boolean;

    hide: () => void;

    accept: ( discountType: string, discountValue: number) => void;

    orderId?: number | undefined,
    invoiceDiscountType: string | undefined;
    invoiceDiscountValue: number | undefined;

}
export type OrderApplyDiscountDialogState = {
    /**
     *
     */
    invoiceDiscountType: string | undefined;
    invoiceDiscountValue: number | undefined;

    visible: boolean;
}
export const ApplyDiscountDialog: FC<OrderApplyDiscountDialogProps> = ({ visible, hide, accept, orderId, 
    invoiceDiscountType, invoiceDiscountValue }) => {
    const [data, setData] = useState<OrderApplyDiscountDialogState>({
            invoiceDiscountType: invoiceDiscountType,
            invoiceDiscountValue: invoiceDiscountValue,
            visible: false
    })
    const ApplyDiscountFooter = () => {
        return (<div>
           <Button label={tawreedTranslationService.translate('lbl_cancel')} icon={PrimeIcons.TIMES} onClick={hide} className="p-button-text" />
                <Button label={tawreedTranslationService.translate('lbl_accept')}
                    icon={PrimeIcons.CHECK}
                    onClick={()=>accept(data.invoiceDiscountType!,data.invoiceDiscountValue!)}
                    disabled={!data || !data.invoiceDiscountType || data.invoiceDiscountType! ==="0" || data.invoiceDiscountValue!<0} />

        </div>);
    }


    // const onHide: ()=> {
    //     setData({ ...state, visible: false, invoiceDiscountType: undefined, invoiceDiscountValue: undefined });
    // }

      //e
      return (
        <Dialog header={tawreedTranslationService.translate("lbl_sales_orders_applay_discount")}
            footer={ApplyDiscountFooter()}
            onHide={hide}
            visible={true}
            position="top" style={{ width: '55vw' }}
            draggable={false}
            closable={true}
           
            focusOnShow
            id="orders_confirm_dialog"
            closeOnEscape={true}
            >
            <div className="grid formgrid">
            <TawreedFormField className="field col-6 lg:col-6 md:col-6" name="discountType" title="lbl_price_scheme_discountType">
                        <InvoiceDiscountTypesDropDown value={data?.invoiceDiscountType} name="discountType" render="standalone" onChange={(e) => {
                            setData({ ...data, invoiceDiscountType: e.value })
                        }} />
                    </TawreedFormField>
                    <TawreedFormField name="bonus" title={"lbl_store_products_discount_bonus_val"} className="field col-6 lg:col-6 md:col-6">
                        <TawreedInputNumber mode={'decimal'} value={data.invoiceDiscountValue} name="bonus" render="standalone" onChange={(e) => {
                            setData({ ...data, invoiceDiscountValue: e.value ? e.value : 0 });
                        }} />
                    </TawreedFormField>
            </div>
        </Dialog>
    );
}
