import { BonusDto } from "../../../../catalog/store-products/domain";
import { TawreedMenuItem } from "../../../../constants";
import { Partner } from "../../../../partners/data";
import { PriceListItem } from "../../domain";
import { OrderDocumentFileDto, OrderFilesDTO } from "../../domain/dtos/order-documents";

export interface OrderItem {
    orderItemId: number;
    cartItemId: number;
    orderId: number;
    expireDate: Date;
    itemTotal: number;
    cancelledItemTotal: number;
    productName: string;
    quantity: number;
    quantityCancelled: number;
    quantityOrdered: number;
    quantityReturned: number;
    retailPrice: number;
    returnQuantity: number;
    salePrice: number;
    storeName: string;
    customerName: string;
    orderItemName: string;
    itemGrossTotal?: number;
    tax?: number;
    itemTotalTax?: number;
    sellingPrice?: number;
    discountType?: string;
    discountValue?: number;
    bonus?: number;
    storeProductTax?: number;
    bonusType?: string;
    bonuses: Array<BonusDto>;
    itemTotalDiscount?: number;
    storeItemId?:string;
}

export interface Order {
    cancelReason?: TawreedMenuItem;
    cartId: number;
    commentsList: Array<any>;
    commissionAmount: number;
    commissionPercent: number;
    commissionReportURI: string;
    confirmMessage: string;
    customer: Partner;
    deliveryDate: Date;
    editMessage: string;
    feedbackId: number;
    invoiceDate: Date;
    invoiceNo: string;
    items: Array<OrderItem>;
    itemsCount: number;
    itemsQuantity: number;
    langCode: string;
    orderDate: Date;
    orderId: number;
    orderReportURI: string;
    shipmentOrderReportURI: string;
    orderItemsReportURI?: string;
    orderTotal: number;
    orderTotalStore: number;
    paymentMethodId: number;
    returnReasonId: number;
    shipmentDate: Date;
    shipmentMethodId: number;
    shipmentNo: number;
    shippingTotal: number;
    showCancel: boolean;
    showCommissionPrint: boolean;
    showConfirm: boolean;
    showDelete: boolean;
    showDeliver: boolean;
    showEdit: boolean;
    showNotifyCustomer: boolean;
    showPrint: boolean;
    showReturn: boolean;
    showShip: boolean;
    showReadyForDelivery: boolean;
    showShipment: boolean;
    editShipment: boolean;
    showVerify: boolean;
    siteId: number;
    statusId: OrderStatus;
    store: Partner;
    subTotal: number;
    taxPercent: number;
    total: number;
    totalDiscount: number;
    carrier: Partner;
    updatedAt: Date;
    salesOrderId: number | undefined;
    historyList: HistoryItem[];
    grossTotal?: number;
    totalTax?: number;
    priceListItem: PriceListItem;
    paymentModeId: number;
    payThroughUs?: boolean;
    salesOfferId?: number;
    salesOfferTitle?: string;
    requiredOtp?: boolean;
    fiels?: Array<OrderDocumentFileDto>,
    notes?: Array<OrderNote>,
    invoiceTotalDiscount?: number;
    invoiceDiscountType?: string;
    invoiceDiscountValue?: number;
    showInvoiceDiscount?: boolean;
}

export interface OrderNote{
    salesOrderNoteId: number;
    salesOrderId: number;
    createdAt: Date;
    note: string;
    userId: number;
    userName: string;
    mobileNo: string;
    email: string;
    partner: Partner
}

export interface HistoryItem {
    historyId: number;
    orderId: number;
    statusId: OrderStatus;
    statusDate: Date;
    note: string;
    createdAt: Date;
    updatedAt: Date;
    createdBy: number;
    updatedBy: number;
}


export enum OrderStatus {
    Placed = 1,
    Processing = 2,
    Shipped = 3,
    Delivered = 4,
    Cancelled = 5,
    Returned = 6,
    READY_FOR_DELIVERY = 7,
}
