import React from "react";
import {Tag} from "primereact/tag";

import {ObjectUtils} from "../../../../../helpers/object";
import {TawreedMenu} from "../../../../../modules/constants";
import {tawreedTranslationService} from "../../../../../common/translations";
import { paymentModeDto } from "../../domain";
import { AuthUtils } from "../../../../auth/domain";

export type PaymentItemTemplateProps = {
    /**
     *
     */
    rowData: paymentModeDto;

}

export const PaymentItemTemplate: React.FC<PaymentItemTemplateProps> = (props) => {

    return (
        <div className="">
            <div className="flex">
                <span className="mr-auto">
                    {AuthUtils.current().language === 'en' ? props.rowData.titleEn : props.rowData.titleAr}
                    </span>
                   
                {/* <Tag rounded severity={'success'}
                     style={{width: '5rem'}}
                     value={(props.rowData.partnerType && props.partnersMenu) ? ObjectUtils.fromMenu(props.rowData.partnerType, props.partnersMenu) : tawreedTranslationService.translate('lbl_partner_' + props.rowData.partnerType)}/> */}
            </div>
            <div style={{fontSize: '.75rem', color: 'var(--text-danger-color)'}}>
            <span className="ml-auto">
                    {props.rowData.msg}
                    </span>
            </div>
        </div>
    );
}
